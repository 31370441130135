:root {
  --secondaryFont: "Noto Serif", serif;
  --backgroundImage: url('/images/settings/background_image.svg') center center/contain no-repeat;
  --diamondImage: url('/images/settings/diamond_icon.png') center center/contain no-repeat;
  --arrowImg: url('/images/settings/arrow_icon.svg') center center/cover no-repeat;
  --settingsListImage: url('/images/settings/settings_list_icon.svg');
  --remindersListImage: url('/images/settings/reminders_list_icon.svg');
  --languageListImage: url('/images/settings/language_list_icon.svg');
  --referencesListImage: url('/images/settings/references_list_icon.svg');

  --pregnancySettingsImage: url('/images/settings/pregnancy_icon.svg');
  --accountSettingsImage: url('/images/settings/references_list_icon.svg');
  --ideologySettingsImage: url('/images/settings/islam_icon.svg');

  --settings__generalFontSize: 22px;
  --settings__digitFontSize: 36px;
  --settings__subtitleFontSize: 16px;
  --settings__defaultTextSize: 16px;
  --settings__buttonFontSize: 18px;
  --settings__backFontSize: 16px;
  --settings__textPagefont: 14px;
  --settings__langTitle:20px;

  --settings__backgroundColor: linear-gradient(0deg, #FAFBFF, #FAFBFF), var(--pink);
  --fullWhite: #FFF;
  --settings__lightGrey: #BFCBDB;
  --settings__mediumGrey: #9FA9B5;
  --settings__transparentGrey: #E3ECFB;
  --settings__blue: #75A2E9;
  --settings__pinkTransparent: #FFE1DB;
  --settings__orangeTransparent: #F47F64;

  --settings__buttonShadow: 0px 4px 20px rgba(37, 80, 149, 0.5);
  --settings__cancelSaveShadow: 0px 0px 6px rgba(117, 162, 233, 0.7);;
  --settings__selectedShadow: 0px 0px 4px rgba(117, 162, 233, 0.5);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.SettingsPage {
  font-family: var(--defaultFont);
  font-display: swap;
  color: var(--grey);
}

.SettingsPage .backgroundImage {
  content: var(--backgroundImage);
  width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 1100px;
}

.SettingsPage .keyboard  {
  height: 32vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  background-color: var(--grey);
}

.SettingsPage .notification {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  left: 0;
  top: 0;
}

.SettingsPage .notification .lang {
  font-size: var(--settings__langTitle);
  width: 100%;
  color: var(--black);
  text-align: center;
}

.SettingsPage .notification p {
  text-align: center;
  font-size: var(--settings__backFontSize);
}

.SettingsPage.screen {
  height: 100vh;
  width: 100vw;
  padding: 33px 0 0;
  overflow: hidden;
}

.SettingsPage.homePage {
  height: auto;
  padding:0;
}

.SettingsPage.homePage .header {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 80%;
  max-width: 800px;
}

.SettingsPage .header {
  display: flex;
  flex-flow: wrap;
  height: 270px;
  position: relative;
  padding-top: 25px;
}

.SettingsPage .title {
  color: var(--black);
  font-size:var(--settings__generalFontSize);
  text-align: center;
  width: 100%;
}

.SettingsPage .textPage {
  height: auto;
}

.SettingsPage .textPage .title {
  padding-top: 30px;
}

.SettingsPage .textPage .content {
  padding:50px 10px 130px;
}

.SettingsPage .textPage p, .textPage span {
  color: var(--black);
  font-size: var(--settings__textPagefont);
}

.SettingsPage .textPage p span {
  font-weight: var(--bold);
}

.SettingsPage .italic {
  font-style: italic;
}

.SettingsPage .stats {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin: 30px auto 0;
}

.SettingsPage .stats li  {
  width: 40%;
  color: var(--fullWhite);
  text-align: center;
}

.SettingsPage .stats::after {
  content: '';
  width: 1px;
  height: 100%;
  background-color: var(--settings__transparentGrey);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.SettingsPage .digit {
  font-family: var(--secondaryFont);
  font-display: swap;
  font-size: var(--settings__digitFontSize);
  display: block;
  color: var(--fullWhite);
}

.SettingsPage .keyboardScreen .digit {
  color: var(--settings__blue);
  text-align: center;
}

.SettingsPage.homePage span:nth-of-type(2) {
  font-size: var(--settings__subtitleFontSize);
  color: var(--fullWhite);
}

.SettingsPage .content button, .SettingsPage .notification button {
  font-size:var(--settings__buttonFontSize);
  position: relative;
  background-color: var(--settings__orangeTransparent);
  appearance:none;
  border:none;
  border-radius: 30px;
  color: var(--fullWhite);
  padding: 15px 0;
  width: 90%;
  display: block;
  margin: 15px auto 60px;
  max-width: 400px;
}

.SettingsPage .notification button {
  margin: 20px auto;
  padding: 8px 0;
}

.SettingsPage.screen .set {
  color: var(--settings__blue);
}

.SettingsPage .navigationButtons {
  width: 100%;
  position: relative;
}

.SettingsPage .navigationButtons li {
  background: var(--fullWhite);
  box-shadow: var(--settings__selectedShadow);
  padding: 17px 10px 11px;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}

.SettingsPage.homePage .navigationButtons li {
    padding: 17px 20% 11px;
}

.SettingsPage .keyboardScreen .navigationButtons li:last-child {
  display: block;
}

.SettingsPage .keyboardScreen .navigationButtons li:last-child .set {
  float: right;
}

.SettingsPage .cancelSave-container {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}

.SettingsPage .cancelSave {
   margin:10px;
   font-size: 14px;
   width: 125px;
   padding: 8px 0;
   box-shadow: var(--settings__cancelSaveShadow);
}

.SettingsPage .cancelSave:focus {
  outline: none;
}

.SettingsPage .cancelSave:first-child {
  background-color: transparent;
  color: var(--settings__mediumGrey);
  margin-right: 10px;
}

.SettingsPage.screen .overflow {
  overflow: hidden;
  width: 100%;
}

.SettingsPage.screen .chooseSchedule {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.SettingsPage.screen .chooseSchedule li {
  color: var(--lgrey);
  box-shadow: none;
  margin: 0 14px;
  font-size: 22px;
  line-height: 20px;
  font-weight: var(--light);
}

.SettingsPage.screen .chooseSchedule li.selected {
  color: var(--settings__blue);
  font-size: 28px;
  font-weight: var(--medium);
}

.SettingsPage .chooseLanguage {
  width: 100%;
  position: relative;
  padding: 0 15px;
}

.SettingsPage .chooseLanguage li, .text_container, .input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SettingsPage .chooseLanguage li {
  margin-bottom: 10px;
}

.SettingsPage .chooseLanguage li .text_container span {
  color: var(--black);
  font-size: var(--settings__defaultTextSize);
  margin-left: 10px;
  transform: translateY(2px);
}

.SettingsPage .input_container input {
  width: 16px;
  height: 16px;
}

.SettingsPage .set input {
  width: 26px;
}

.SettingsPage .editWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SettingsPage .editWrapper .editButton {
  position: unset;
  padding-right: 5px;
}

.SettingsPage .specPage .navigationButtons li::after, .privacyPage .navigationButtons li::after {
  content: '';
  position: absolute;
  right: 30px;
  transform: translateY(-50%);
  top: 50%;
  width: 8px;
  height: 15px;
  background: var(--arrowImg);
}

.SettingsPage .specPage .navigationButtons li:nth-child(4) {
  margin-top: 80px;
}

.SettingsPage .specPage .navigationButtons li:last-child span:nth-child(1)::before {
  content: none;
}

.SettingsPage > .specPage .navigationButtons span {
  position: relative;
  display: block;
}

.SettingsPage .navigationButtons span span {
  font-size: 12px;
  color: var(--settings__lightGrey);
  position: absolute;
  left: 11px;
  bottom: 2px;
}

.SettingsPage.homePage .navigationButtons li::before {
  content: '';
  position: absolute;
  left: 10%;
  transform: translateY(-50%);
  top: 50%;
  width: 20px;
  height: 23px;
}

.SettingsPage.homePage .navigationButtons li.habitSettings::before {
  background: var(--settingsListImage);
}

.SettingsPage.homePage .navigationButtons li.languageSettings::before {
  background: var(--languageListImage);
}

.SettingsPage.homePage .navigationButtons li.referencesSection::before {
  background: var(--referencesListImage);
}

.SettingsPage.homePage .navigationButtons li.pregnancySettings::before {
  background: var(--pregnancySettingsImage);
}

.SettingsPage.homePage .navigationButtons li.accountSettings::before {
  background: var(--accountSettingsImage);
}

.SettingsPage.homePage .navigationButtons li.ideologySettings::before {
  background: var(--ideologySettingsImage);
}

.SettingsPage .button {
  width: 46px;
  height: 27px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  border: 1.5px solid #BFCBDB;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.SettingsPage .button .circle {
  border-radius: 20px;
  width: 23px;
  height: 23px;
  border-radius: 40px;
  background-color: var(--fullWhite);
  border: 0.5px solid rgba(0, 0, 0, 0.101987);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1);
}

.SettingsPage .button.on {
  background-color: var(--settings__blue);
  justify-content: flex-end;
}

.SettingsPage .content .onOff {
  color: var(--settings__lightGrey);
  margin-right: 12%;
  line-height: inherit;
}

.SettingsPage .homePageContainer button::before {
  content: '';
  position: absolute;
  bottom: -80px;
  background: var(--diamondImage);
  width: 26px;
  height: 32px;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
}

.SettingsPage .homePageContainer .subButton {
  position: absolute;
  color: var(--black);
  width: 100%;
  top: 60px;
  display: block;
  text-align: center;
}

.SettingsPage .top_header .back {
  color: var(--settings__blue);
  font-size: var(--settings__backFontSize);
  position: absolute;
  left: 10px;
}

.SettingsPage .content {
  margin-top: 30px;
}

.SettingsPage .homePageContainer {
  margin-top: 0;
  position: relative;
  padding-bottom: 200px;
}

.SettingsPage.screen .footer {
  bottom: 0;
  left: 0px;
  width: 100%;
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  box-shadow: var(--settings__buttonShadow);
  background-color: var(--fullWhite);
  height: 82px;
  position: fixed;
}

.SettingsPage.screen .footer .roundBorder {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: -20px;
  background: var(--fullWhite);
  z-index: 1;
}

.SettingsPage.screen .footer .white-bg {
  height: 82px;
  background: var(--fullWhite);
  z-index: 2;
  position: relative;
  width: 100%;
}

.SettingsPage.screen .footer .img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: -15px;
  z-index: 3;
  background: var(--settings__pinkTransparent);
}

.SettingsPage.screen .footer .footerButtons {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

.SettingsPage.screen .footer .footerButtons li {
  margin: 20px 10px;
  display: flex;
  justify-content: space-between;
  width: 30%;
  position: relative;
}

.SettingsPage.screen .footer .footerButtons li .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.SettingsPage.screen .footer .footerButtons li .imgContainer {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.SettingsPage.screen .footer .footerButtons li span {
  text-align: center;
  display: block;
  font-size: var(--daysFontSize);
  color:var(--settings__lightGrey);
}

.SettingsPage.screen::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  background: var(--settings__backgroundColor);
  left: 0;
  top: 0;
  z-index: -2;
}
@media (min-width: 376px) and (min-height: 668px) { /* big devices*/
  .SettingsPage.homePage .header {
    margin-bottom: 50px;
    top: 4vh;
  }
}
@media (max-width: 375px) and (max-height: 667px) { /*small devices"*/
  :root {
    --settings__defaultTextSize:14px;
    --settings__generalFontSize:18px;
    --settings__digitFontSize:28px;
    --settings__subtitleFontSize:12px;
    --settings__backFontSize: 14px;
    --settings__langTitle:18px;
  }

  .SettingsPage.screen {
    height: auto;
    padding-bottom: 200px;
  }

  .SettingsPage.homePage {
    padding-bottom: 0;
  }

  .SettingsPage .textPage {
    padding-bottom: 0px;
  }

  .SettingsPage .content {
    margin-top: 10px;
  }

  .SettingsPage.screen::before {
    height: 170px;
  }

  .SettingsPage.screen button {
    padding: 9px 0;
  }

  .SettingsPage .multiStepWizardContainerMerged {
    bottom: -20px;
  }

  .SettingsPage .multiStepWizardContainerMerged button.wizardStepNext {
    margin-bottom: 0px;
  }

  .SettingsPage.screen .footer {
    height: 62px;
  }

  .SettingsPage.screen .footer .footerButtons li {
    margin: 5px 10px;
  }

  .SettingsPage.screen .footer .img {
    width: 64px;
    height: 64px;
  }

  .SettingsPage.screen .footer .roundBorder {
    width: 69px;
    height: 67px;
  }
}

@media (min-width: 768px) {
  .SettingsPage.homePage .header {
    top: 10vh;
  }

  .SettingsPage.homePage .navigationButtons {
    width: 70%;
    margin: 0 auto;
    max-width: 1100px;
  }
}

@media (min-width: 1200px) {
  .SettingsPage.homePage .header {
    top: 15vh;
  }

  .SettingsPage.homePage .navigationButtons {
    width: 70%;
    margin: 0 auto;
    max-width: 800px;
  }

  .SettingsPage .backgroundImage {
    content: var(--backgroundImage);
    max-width: 800px
  }
}

@media (max-width: 812px) and (max-height: 414px) { /*horizontal*/
  :root {
    --settings__generalFontSize:24px;
    --settings__defaultTextSize:16px;
  }

  .SettingsPage.homePage .header {
    margin-bottom: 20px;
  }

  .SettingsPage .homePageContainer {
    padding-bottom: 20px;
  }

  .SettingsPage.screen {
    padding: 0 15px 90px;
    height: auto;
  }

  .SettingsPage.homePage {
    padding: 0 0 90px;
    height: auto;
  }

  .SettingsPage.screen .footer {
    height: 55px;
  }

  .SettingsPage.screen .footer .roundBorder {
    width: 65px;
    height: 65px;
  }

  .SettingsPage.screen .footer .img {
    width: 60px;
    height: 60px;
  }

  .SettingsPage .keyboard {
    display: none;
  }

  .SettingsPage.screen .footer .white-bg {
    height: 55px;
  }

  .SettingsPage.screen .footer .footerButtons li {
    margin: 5px 50px;
  }

  .SettingsPage .keyboardScreen {
    height: auto ;
    padding-bottom: 0;
  }
}

@media (min-height: 500px) { /*horizontal*/
  .SettingsPage .multiStepWizardContainerMerged {
    bottom: 100px;
  }
}
