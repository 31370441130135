:root {
	--cloudBottomImg: url('/images/onboarding/cloud_bottom.svg');
	--cloudMiddleImg: url('/images/onboarding/cloud_middle.svg');
	--cloudTopImg: url('/images/onboarding/cloud_top.svg');
  --dropImg: url('/images/onboarding/drop.svg');
  --calendarDateBackground: #9e9e9e0a;

	--blue: #1e90ff;
	--orange: #F47F64;
	--backgroundOrange:#FC7D7B;
	--backgroundViolet: #8E78FF;
	--black: #494E59;
	--gradient: linear-gradient(45deg,var(--backgroundViolet),var(--backgroundOrange));
	--white: #FAFBFF;
  --grey:#6A7989;
  --greyTransparent:#BFCBDB;
  --transparentWhite:#FB9B9A;
	--lightGrey: #DBE4F0;
	--buttonShadow: 0px 0px 20px rgba(67, 15, 99, 0.46);
  --circleBorder: 0.5px solid rgba(0, 0, 0, 0.101987);
  --circleShadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1);

	--defaultFont:'Baloo Tamma 2';
	--bold: 700;
	--normal: 500;
	--light: 400;
	--generalFont:32px;
	--subtitleFont:24px;
	--subtitleLineHeight:30px;
	--buttonFontSize:20px;
	--termsFont:12px;
	--termsOfUseLineHeight:10px;

  --titleMarginBottom:0 0 10px 0;
  --titleWidth: 195px;
  --titleRelativePosition: -10vh;

  --subtitleRelativePosition: -8vh;

  --buttonPadding:8px 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.fixed-ltr { unicode-bidi: embed; direction: ltr; }
.rtl { unicode-bidi: embed; direction: rtl; }
.ltr { unicode-bidi: embed; direction: ltr; }

.OnboardingPage {
  font-family: var(--defaultFont);
  font-display: swap;
}

.OnboardingPage .wizardProgressSlider {
  position: absolute;
  z-index: 99;
  display: flex;
  width: 100px;
  height: 10px;
  transform: rotate(90deg) translate3d(50%, -100%, 0);
  right: 0;
  top: 50px;

  unicode-bidi: embed;
  direction: ltr;
}

.OnboardingPage .wizardProgressSlider li {
  background-color: var(--transparentWhite);
  width: 10px;
  height: 10px;
  border-radius: 40px;
  margin: 0 5px;
}

.OnboardingPage .wizardProgressSlider li.active {
  background-color: var(--white);
}

.OnboardingPage.screen {
  height: 100vh;
  width: 100vw;
  background: var(--gradient);
  display: flex;
  justify-content:center;
  flex-direction: column;
  padding: 20px 24px;
  overflow: hidden;
}

.blur > *:not(.infoModal) {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.OnboardingPage .infoModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 1;
}

.OnboardingPage .infoModalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  padding: 1rem 1.5rem;
  width: 24rem;
  border-radius: 0.5rem;
  line-height: 17px;
}

.OnboardingPage .closeButton {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
  transform: translate(15px, -15px);
}

.OnboardingPage .closeButton:hover {
  background-color: darkgray;
}

[dir=rtl] .OnboardingPage .closeButton {
  float: left;
  transform: translate(-15px, -15px);
}

.OnboardingPage .showInfoModal {
  opacity: 1;
  visibility: visible;
  transform: scale(1.0);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;

  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
}

.OnboardingPage .title {
  font-size: var(--generalFont);
  width: var(--titleWidth);
  line-height: 30px;
  color: var(--white);
  transform: translateY(var(--titleRelativePosition));
  margin: var(--titleMarginBottom);
}

.OnboardingPage .infoIcon {
  transform: scale(0.4);
  vertical-align: middle;
  margin-left: -13px;
}

.OnboardingPage .subtitle {
  width: 100%;
  font-size: var(--subtitleFont);
  color: var(--black);
  line-height: var(--subtitleLineHeight);
  transform: translateY(var(--subtitleRelativePosition));
  margin-top: var(--subtitleMargin);
  position: relative;
}

.OnboardingPage .multiStepWizardContainer {
  position: absolute;
  left: 10px;
  bottom: 20px;
  width: calc(100% - 20px);
  z-index: 1;
}

.OnboardingPage .wizardStepNext {
  color: var(--orange);
  font-size: var(--buttonFontSize);
  font-weight: var(--normal);
  width: 100%;
  box-shadow: var(--buttonShadow);
  border-radius: 30px;
  background-color: var(--white);
  position: relative;
  border: none;
  z-index: 2;
  padding: var(--buttonPadding);
  margin: 0 auto;
  display: block;
}

.OnboardingPage .wizardStepNext:focus {
  outline: none;
}

.OnboardingPage .iDontRemember, .skipThisStep {
  font-weight: 700;
  text-decoration: underline;
  text-align: center;
  color: var(--lightGrey);
  font-size: 14px;
  display: block;
  line-height: 15px;
  margin: 10px 0;
}

.OnboardingPage .termsOfUse {
  transform: translateY(0vh);
  font-size: var(--termsFont);
  color: var(--lightGrey);
  text-align: center;
  line-height: var(--termsOfUseLineHeight);
  margin-top: 10px;
  position: relative;
  z-index: 2;
}

.OnboardingPage .termsOfUse a {
  color: var(--white);
}

.OnboardingPage .resize {
  display: block;
}

@media (max-width: 375px) {
  .OnboardingPage .infoModalContent {
    width: 90%;
  }
}

@media (max-width: 375px) and (max-height: 700px) { /*iphone 5se/6 */
  :root {
    --generalFont: 24px;
    --subtitleFont:16px;
    --subtitleLineHeight:20px;
    --termsFont:10px;
    --buttonFontSize:14px;

    --titleMarginBottom:0px;
  }
}

@media (min-width: 767px) {
  .OnboardingPage .wizardStepNext {
    width: 70%;
  }
}

@media (max-width: 767px) { /*for all mobile devices*/
  :root {
    --buttonPadding:6px 0;
  }
  .OnboardingPage .wizardStepNext {
    width: 80%;
  }

  .OnboardingPage .iDontRemember, .OnboardingPage .skipThisStep {
    margin: 5px 0;
  }
}
@media (max-width: 820px) and (max-height: 414px) { /* this is for Iphone SE horisontal mode */
  :root {
    --generalFont: 24px;
    --titleTopCloudImgTop:-25vh;
  }
  br {
    display: none;
  }
  .OnboardingPage .wizardProgressSlider {
    top: 20px;
  }

  .OnboardingPage.screen {
    height: auto;
    min-height: 100vh;
  }

  .OnboardingPage .multiStepWizardContainer {
    bottom: 5px;
  }

  .OnboardingPage .iDontRemember {
    margin: 5px 0;
  }
}
